import { FC } from 'react'

interface KlarnaLogoProps {
	color: string
	className?: string
}

export const KlarnaLogo: FC<KlarnaLogoProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 42 9"
			className={props.className}
		>
			<g
				fill={props.color}
				clipPath="url(#clip0_2030_5195)"
			>
				<path d="M7.9 0H5.916a5.07 5.07 0 01-2.051 4.094l-.786.588L6.12 8.835h2.506l-2.802-3.82A7.023 7.023 0 007.901 0zM.803 8.834h2.033V0H.803v8.834zM9.217 8.831h1.914V.001H9.217v8.83zM27.923 2.56c-.73 0-1.421.227-1.883.845v-.681H24.22v6.107h1.843v-3.21c0-.93.622-1.385 1.372-1.385.805 0 1.266.48 1.266 1.37v3.225h1.827V4.944c0-1.42-1.13-2.387-2.604-2.387v.003zM15.253 7.35a1.616 1.616 0 01-1.656-1.572 1.659 1.659 0 013.314 0 1.616 1.616 0 01-1.658 1.571zm1.663-4.626v.39A3.222 3.222 0 1015.104 9a3.192 3.192 0 001.812-.55v.39h1.83V2.723h-1.83zM21.573 3.52v-.796h-1.872v6.107h1.876v-2.85c0-.964 1.042-1.481 1.767-1.481h.02V2.724c-.741 0-1.424.318-1.79.796zM34.544 7.35a1.616 1.616 0 01-1.656-1.572 1.66 1.66 0 013.314 0 1.615 1.615 0 01-1.658 1.571zm1.665-4.626v.39a3.223 3.223 0 100 5.327v.39h1.828V2.724H36.21zM39.979 6.665a1.15 1.15 0 10-.003 2.298 1.15 1.15 0 00.003-2.298z" />
			</g>
			<defs>
				<clipPath id="clip0_2030_5195">
					<path
						fill={props.color}
						d="M0 0H40.324V9H0z"
						transform="translate(.803)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
